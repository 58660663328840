#container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-container {
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 450px;
    text-align: center;
    padding: 25px;
}

h1 {
    margin-bottom: 24px;
    color: #333;
    font-size: 24px;
}

input {
    width: calc(100% - 20px);
    padding: 12px 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s;
}

input:focus {
    border-color: #F0A43A;
    outline: none;
}

button {
    width: 100%;
    background-color: #F0A43A;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #e0932e;
}

.reset-link {
    margin-top: 20px;
    display: inline-block;
    color: #F0A43A;
    text-decoration: none;
    font-weight: bold;
}

.reset-link:hover {
    text-decoration: underline;
}

.error-message {
    color: #ff0000;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
}