.summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.summary-table th, .summary-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

@media (max-width: 576px) {
    .summary-table {
        width: 100%;
        overflow-x: auto;
        margin-top: 40px; /* Enable horizontal scrolling on small screens */
    }

    .summary-table th,
    .summary-table td {
        white-space: nowrap; /* Prevent text wrapping */
    }
}