.csv-uploader {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .upload-section {
    margin-bottom: 20px;
  }
  
  .upload-section h2 {
    margin-bottom: 10px;
  }
  
  .upload-section input[type="file"] {
    margin-right: 10px;
  }
  
  .uploaded-data-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .uploaded-data-section h2 {
    margin-bottom: 10px;
  }
  
  .data-table {
    margin-top: 20px;
  }
  
  .data-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th,
  .data-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .data-table th {
    background-color: #f2f2f2;
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  