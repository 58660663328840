


h1, h2 {
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.project_totals h1{
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
}