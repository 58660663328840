.abcdef {
    
    color: rgb(0, 0, 0);
  }
  

@media (max-width: 576px) {
    .abc {
        margin-top: 50px;
    }
  }