/* Reset default list styles */
.project-list {
    list-style: none;
    padding: 0;
}

/* Style for each project item */
.project-item {
    border: 1px solid #eee;
    margin: 15px 0;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.project-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Style for links within project items */
.project-item a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
}

/* Style for project images */
.project-image {
    max-width: 100px;
    margin-right: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for project details */
.project-details {
    flex-grow: 1;
}

/* Style for project titles */
.project-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

/* Style for project descriptions */
.project-description {
    font-size: 14px;
    line-height: 1.5;
    color: #666;
}
