.landing-page {
    background-color: #f0f0f0;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .greeting {
    color: #333;
    font-size: 3em;
    margin-bottom: 0;
  }
  
  .date {
    color: #666;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .projects-heading {
    color: #333;
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .project-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .project-item {
    margin-bottom: 20px;
  }
  
  .project-title {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  
  .project-description {
    color: #666;
    font-size: 1.2em;
  }