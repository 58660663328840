/* styles.css */

/* Container styles */


/* Form styles */
form {
    display: flex;
    flex-direction: column;
}

/* Form fields styles */
form > div {
    margin-bottom: 15px;
}

/* Label styles */
label {
    font-weight: bold;
}

/* Input styles */
input[type="text"],
input[type="number"],
select,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Checkbox styles */
input[type="checkbox"] {
    margin-right: 5px;
}

/* Button styles */
button {
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #000000;
}

/* Success message styles */
.success-message {
    color: green;
    margin-top: 10px;
}

/* Error message styles */
.error-message {
    color: red;
    margin-top: 10px;
}
@media (max-width: 575.98px) {
    .name {
        padding-top: 50px;
    }
  }