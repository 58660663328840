.create-room-type-container h1 {
    margin-bottom: 20px;
}

.create-room-type-container form {
    margin-bottom: 20px;
}

.room-type-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
}

@media (max-width: 575.98px) {
    .name {
        padding-top: 50px;
    }
  }