.margin-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.margin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.margin-item h3 {
    margin-bottom: 3px;
    font-size: 16px;
    color: #333;
}

.margin-item span {
    font-size: 14px;
    color: #007bff;
    font-weight: bold;
}

.margin-item button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.margin-item button:hover {
    background-color: #0056b3;
}

.project-name {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
    padding-top: 15px;
    font-family: "Lucida Console", "Courier New", monospace;
}

.title {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
    font-family: "Lucida Console", "Courier New", monospace;
}