body {
    padding-top: 70px; /* Adjusted to accommodate the fixed navbar */
}

.navbar-brand img {
    height: 45px;
    width: 100px;
}

.navbar {
    background-color: #000000;
}

.navbar-toggler-icon {
    background-color: #000; /* Customize the navbar-toggler icon color */
}

.navbar-nav .nav-link {
    color: #009FE3;
}

.navbar-nav .nav-link.active {
    color: #c1567a; /* Customize the active link color */
}

.btn-nav {
    background-color: #000000;
    color: #009FE3;
}

.btn-nav:hover {
    background-color: #000000; 
}

.abc {
    color: #009FE3;

}
.abcd {
    color: #009FE3;

}

@media (max-width: 575.98px) {
    .abc {
      color : #009FE3;
      margin-left: -8px;
    }
    .abcd{
        color : #009FE3;
        padding-left: 12px;
      }
    .btn-nav {
        background-color: #000000;
        color: #009FE3;
        margin-top: -5px;
    }
  }
