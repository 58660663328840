.maintenance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.maintenance-table th, .maintenance-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.edit-button {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #000000;
    color: white;
    border-color: #000000;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #000000;
}

.edit-block {
    display: block; /* Display as a block-level element */
    width: 100%; /* Take full width of its container */
    text-align: center; /* Optionally center the text */
    color : #000000;
    /* Add any other styles you need */
}

.building-name {
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
}
@media (max-width: 575.98px) {
    .building-name {
        font-size: 24px;
        font-weight: bold;
        color: #333; /* Adjust color as needed */
        padding-top: 50px;
    }
  }