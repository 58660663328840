.delete-building-container {
    text-align: center;
    margin-top: 2rem;
}

.delete-building-container h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.delete-building-container .delete-error-message {
    color: red;
    margin-top: 1rem;
}

.delete-building-container .delete-success-message {
    color: green;
    margin-top: 1rem;
}

.delete-building-container button {
    background-color: #FC4747;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.delete-building-container button:hover {
    background-color: #FF6B6B;
}
.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.button-container button {
    background-color: rgb(255, 0, 0); /* Green */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-left: 10px;
    margin-right: 10px;
}

.button-container button:hover {
    background-color: #ff0000; /* Darker Green */
}

.button-container button.cancel {
    background-color: #000000; /* Red */
}

.button-container button.cancel:hover {
    background-color: #000000; /* Darker Red */
}

@media (max-width: 576px) {
    .delete-title {
        margin-top: 70px;
    }
  }