.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
}

.loading-content {
  text-align: center;
  margin-top: 10px;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-loader-spinner {
  display: inline-block;
  vertical-align: middle;
}

.react-loader-spinner-component {
  display: inline-block;
  vertical-align: middle;
  animation: spinner-animation 1s infinite linear;
}

.react-loader-spinner-box {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  border-top: 3px solid rgba(255, 0, 0, 0.7);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  width: 40px;
  height: 40px;
  animation: loading-animation 1s ease-in-out infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}