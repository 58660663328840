@media (max-width: 576px) {
    .link-container a{
        margin-bottom: 8px; 
        border-radius: 100px;
        margin-right: -10px;
        margin-left: 15px;
        /* Reduce margin between links for smaller screens */
    }

    .project-name {
        margin-top: 50px;
    }
  }