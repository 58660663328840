body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

h1 {
    color: #333;
}

.building-info {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.project-name {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
    padding-top: 15px;
    font-family: "Lucida Console", "Courier New", monospace;
}
.building-name {
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    margin-bottom: 10px;
}

.calculation-info {
    font-size: 16px;
    color: #505050; /* Adjust color as needed */
}

.link-container {
    text-align: center; /* Align links to the center */
}

.link-container a {
    display: inline-block; /* Display links in the same line */
    margin: 0 10px; /* Add spacing between links */
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    border: 1px solid #000000;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: white;
    transition: background-color 0.3s, color 0.3s;
}

.link-container a:hover {
    background-color: #000000;
    color: white;
}

.edit-button {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    border: 1px solid #007bff;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: white;
    transition: background-color 0.3s;
}

.edit-button:hover {
    background-color: #007bff;
    color: white;
}

.building-table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 10px;
}

.building-table th, .building-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

.building-table th {
    background-color: #007bff;
    color: white;
}

.building-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.building-table tbody tr:hover {
    background-color: #e0e0e0;
}

.create-link {
    display: inline-block;
    margin-top: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    border: 1px solid #343a40; /* Dark border color */
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #343a40; /* Dark background color */
    transition: background-color 0.3s;
}

.create-link:hover {
    background-color: #23272b;
    color: #009FE3; /* Darker background color on hover */
}

.edit-delete-buttons {
text-align: right;
margin-top: 20px;
}

.edit-delete-buttons a {
margin-left: 10px;
text-decoration: none;
color: #007bff;
font-weight: bold;
border: 1px solid #007bff;
padding: 10px 15px;
border-radius: 5px;
background-color: white;
transition: background-color 0.3s;
}

.edit-delete-buttons a:hover {
background-color: #007bff;
color: white;
}